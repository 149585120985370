<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('products.campaign.campaign-details') }}</span>
                <el-button
                    type="primary"
                    style="float: right"
                    size="mini"
                    icon="el-icon-check"
                    plain
                    @click.native="saveCampaign"
                    >{{ $t('products.campaign.save') }}</el-button
                >
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">
                        {{ $t('products.campaign.campaign-id') }}
                    </div>
                    <span>{{ campaignId }}</span>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('products.campaign.tooltip-1')"
                            placement="top-start"
                        >
                            <i class="el-icon-info"></i>
                        </el-tooltip>
                        {{ $t('products.campaign.campaign-name') }}
                    </div>
                    <el-input v-model="cloneDetails.numecampanie"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('products.campaign.date-start') }}</div>
                    <el-date-picker
                        v-model="cloneDetails.datastart"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :placeholder="$t('products.campaign.date-start-ph')"
                        class="fullWidth"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('products.campaign.date-end') }}</div>
                    <el-date-picker
                        v-model="cloneDetails.dataend"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :placeholder="$t('products.campaign.date-end-ph')"
                        class="fullWidth"
                    >
                    </el-date-picker>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('products.campaign.discount') }} %</div>
                    <el-input v-model="cloneDetails.reducere"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('products.campaign.campaign-type') }}</div>
                    <el-select v-model="cloneDetails.tip_campanie">
                        <el-option :value="1" label="Promotie"></el-option>
                        <el-option :value="2" label="Lichidare"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-card>
        <div class="separator"></div>

        <el-card>
            <Box :type="'warning'" :active="true">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li>
                            <span style="font-weight: bold; color: firebrick">{{
                                $t('products.campaign.allstoc-warning')
                            }}</span>
                        </li>
                    </ul>
                </div>
            </Box>
        </el-card>
        <div class="separator"></div>

        <el-row :gutter="12">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{ $t('products.campaign.campaign-products') }}</span>
                        <br />
                        <br />
                    </div>
                    <div>
                        <el-input
                            :placeholder="$t('products.campaign.search-ph')"
                            v-model="searchProductTerm"
                            class="input-with-select"
                            v-on:keyup.native.enter="searchProductAction"
                        >
                            <el-button slot="prepend" icon="el-icon-refresh" @click="resetSearchProduct"></el-button>
                            <el-button slot="append" icon="el-icon-search" @click="searchProductAction"></el-button>
                        </el-input>
                    </div>
                    <div class="separator"></div>
                    <el-row :gutter="12">
                        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
                            <label for="allStoc" class="mb-2">{{ $t('products.campaign.allstoc-title') }}</label>
                            <el-input
                                name="allStoc"
                                :style="{ 'margin-top': '0.3rem' }"
                                id="allStoc"
                                v-model="allStoc"
                                class="input-with-select"
                            >
                                <el-button slot="append" class="allstoc-set-btn" type="danger" @click="setAllStoc">
                                    {{ $t('products.campaign.set') }}</el-button
                                >
                            </el-input>
                        </el-col>
                    </el-row>
                    <div class="separator"></div>
                    <el-table :data="searchProductData" border style="width: 100%" size="small">
                        <el-table-column prop="products_name" :label="$t('general.name')">
                            <template slot-scope="scope">
                                <div class="pname">{{ scope.row.products_name }}</div>
                                <div class="identification">
                                    <span class="model"
                                        ><i class="el-icon-tickets"></i>{{ $t('products.campaign.model') }}:
                                        <strong>{{ scope.row.products_model }}</strong></span
                                    >
                                    <span class="vendorId" v-if="hasVendorId(scope.row)"
                                        ><i class="el-icon-paperclip"></i>{{ $t('products.campaign.vendor') }}:
                                        <strong>{{ scope.row.vendor_ext_id }}</strong></span
                                    >
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('products.campaign.promo-price')" width="100">
                            <template slot-scope="scope">
                                <el-input v-model="searchProduct.data[scope.$index].pret_promo" size="mini"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('products.campaign.standard-price')" prop="pretlei" width="100">
                        </el-table-column>
                        <el-table-column :label="$t('products.campaign.stoc')" width="85">
                            <template slot-scope="scope">
                                <el-input v-model="searchProduct.data[scope.$index].stoc" size="mini"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('general.actions')" width="180">
                            <template slot-scope="scope">
                                <el-button @click="editPrice(scope.$index)" type="primary" size="mini" plain>
                                    {{ $t('general.edit') }}</el-button
                                >
                                <el-button type="danger" @click="deleteProduct(scope.row)" size="mini" plain>
                                    {{ $t('general.delete') }}</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="separator"></div>
                    <div class="center">
                        <el-button type="danger" size="medium" style="width: 100%" @click="removeAllProducts">
                            {{ $t('products.campaign.remove-all') }}</el-button
                        >
                    </div>
                    <div class="center" v-if="searchProduct.moreavailable">
                        <div class="separator"></div>
                        <div style="font-size: 14px; font-weight: bold">
                            {{ searchProductParam.start + 10 }}/{{ totalSearch }}
                        </div>
                        <div class="mini-separator"></div>
                        <el-button @click="loadMoreSearch" type="primary" size="medium"
                            >{{ $t('products.campaign.more') }}
                        </el-button>
                    </div>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{ $t('products.campaign.available-products') }}</span>
                    </div>
                    <div>
                        <el-input
                            :placeholder="$t('products.campaign.search-ph2')"
                            v-model="availableProductsTerm"
                            class="input-with-select"
                            v-on:keyup.native.enter="searchAvailableProduct"
                        >
                            <el-button slot="prepend" icon="el-icon-refresh" @click="resetAvailableProducts">
                            </el-button>
                            <el-button slot="append" icon="el-icon-search" @click="searchAvailableProduct"></el-button>
                        </el-input>
                    </div>
                    <div class="separator"></div>
                    <div>
                        <el-input
                            :placeholder="$t('products.campaign.search-ph3')"
                            v-model="availableManufacturersTerm"
                            class="input-with-select"
                            v-on:keyup.native.enter="searchAvailableProduct"
                        >
                            <el-button slot="prepend" icon="el-icon-refresh" @click="resetAvailableProducts">
                            </el-button>
                            <el-button slot="append" icon="el-icon-search" @click="searchAvailableProduct"></el-button>
                        </el-input>
                    </div>
                    <div class="separator"></div>
                    <div>
                        <el-input
                            :placeholder="$t('products.campaign.search-ph4')"
                            v-model="availableCategoriesTerm"
                            class="input-with-select"
                            v-on:keyup.native.enter="searchAvailableProduct"
                        >
                            <el-button slot="prepend" icon="el-icon-refresh" @click="resetAvailableProducts">
                            </el-button>
                            <el-button slot="append" icon="el-icon-search" @click="searchAvailableProduct"></el-button>
                        </el-input>
                    </div>
                    <div class="separator"></div>
                    <el-table :data="availableProducts.data" border style="width: 100%" size="small">
                        <el-table-column prop="products_name" :label="$t('general.name')">
                            <template slot-scope="scope">
                                <div class="pname">{{ scope.row.products_name }}</div>
                                <div class="identification">
                                    <span class="model"
                                        ><i class="el-icon-tickets"></i>{{ $t('products.campaign.model') }}:
                                        <strong>{{ scope.row.products_model }}</strong></span
                                    >

                                    <span class="vendorId" v-if="hasVendorId(scope.row)"
                                        ><i class="el-icon-paperclip"></i>{{ $t('products.campaign.vendor') }}:
                                        <strong>{{ scope.row.vendor_ext_id }}</strong></span
                                    >
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="pretlei" :label="$t('products.campaign.price')" width="100">
                        </el-table-column>
                        <el-table-column prop="stoc" :label="$t('products.campaign.stoc-limitat')" width="85">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.stoc"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('general.actions')" width="95">
                            <template slot-scope="scope">
                                <el-button plain @click="addProduct(scope.row)" type="primary" size="small">
                                    {{ $t('general.add') }}</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="separator"></div>
                    <div class="center">
                        <el-button type="danger" size="medium" style="width: 100%" @click="addAllUsingFilters">
                            {{ $t('products.campaign.add-all') }}</el-button
                        >
                    </div>
                    <div class="center" v-if="availableProducts.moreavailable">
                        <div class="separator"></div>
                        <div style="font-size: 14px; font-weight: bold">
                            {{ availableProductsParam.start + 10 }}/{{ totalAvailable }}
                        </div>
                        <div class="mini-separator"></div>
                        <el-button @click="loadMoreAvailable" type="primary" size="medium">
                            {{ $t('products.campaign.more') }}</el-button
                        >
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
const Box = () => import('vue-info-box-element');
import {
    readCampaign,
    addProductCampaign,
    removeProductCampaign,
    saveProductCampaign,
    saveCampaign,
    setCampaignStoc,
    addAllUsingFilters,
    removeAllProductsFromCampaign,
} from '@/api/produse';
import { debugPromise } from '@/utils/index';
import reduce from 'lodash.reduce';
import isEqual from 'lodash.isequal';
import has from 'lodash.has';
import isEmpty from 'lodash.isempty';

export default {
    components: {
        Box,
    },
    data() {
        return {
            campaignDetails: {},
            cloneDetails: {},
            currentCampaign: '',
            availableProductsParam: {
                name: '',
                start: 0,
                limit: 10,
                search: {
                    byName: '',
                    byManufacturer: '',
                    byCategory: '',
                },
            },
            searchProductParam: {
                name: '',
                start: 0,
                limit: 10,
                search: {
                    byName: '',
                    byManufacturer: '',
                    byCategory: '',
                },
            },
            availableProducts: [],
            searchProduct: {},
            availableProductsTerm: '',
            availableManufacturersTerm: '',
            availableCategoriesTerm: '',
            searchProductTerm: '',
            modifiedPrices: {},
            allStoc: 0,
        };
    },
    methods: {
        setAllStoc() {
            if (this.allStoc < -1) {
                this.sbMsg({
                    type: 'error',
                    message: 'Stocul nu poate fi mai mic de -1',
                });
                return false;
            }
            setCampaignStoc({
                id: this.campaignId,
                stoc: this.allStoc,
            })
                .then((res) => {
                    this.getCampaignDetails(false);
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'setCampaignStoc',
                            params: {
                                id: this.campaignId,
                                stoc: this.allStoc,
                            },
                        },
                    });
                    console.log(e);
                });
        },
        saveCampaign() {
            const vm = this;
            const differences = reduce(
                this.cloneDetails,
                (result, value, key) => {
                    return isEqual(value, vm.campaignDetails[key]) ? result : result.concat(key);
                },
                []
            );
            if (differences.length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('products.campaign.error-no-changes'),
                });
                return false;
            }
            const params = {
                id: this.currentCampaign,
                CampaignData: this.cloneDetails,
            };

            // for(var i in differences) {
            //     params.CampaignData[differences[i]] = this.cloneDetails[differences[i]]
            // }

            this.$confirm('Doriti aplicarea reducerii pentru produsele aflate deja in campanie?', 'Informatie', {
                confirmButtonText: 'Da',
                cancelButtonText: 'Nu',
            })
                .then(({ value }) => {
                    params.applyDiscount = 1;
                    this.saveActualCampaign(params);
                })
                .catch(() => {
                    params.applyDiscount = 0;
                    this.saveActualCampaign(params);
                });
        },
        saveActualCampaign(params) {
            saveCampaign(params)
                .then((res) => {
                    if (has(res.message, 'mesaj') && res.message.mesaj === 'Modificari efectuate cu succes!') {
                        this.sbMsg({
                            type: 'success',
                            message: this.$t('products.campaign.success-save'),
                        });
                        this.currentCampaign = this.cloneDetails.id_campanie; // update current campaign
                        this.getCampaignDetails(false, false);
                        this.updateUrl();
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'saveCampaign',
                            params,
                        },
                    });
                    debugPromise('saveCampaign', e);
                });
        },
        updateUrl() {
            window.history.pushState('', '', window.origin + '/produse/campanie/' + btoa(this.currentCampaign));
        },
        getCampaignDetails(specificSearch, reset, cloneCampaignName) {
            const params = {};
            if (specificSearch !== false) {
                if (specificSearch === 'available') {
                    params.id = this.currentCampaign;
                    params.availableProducts = this.availableProductsParam;
                } else if (specificSearch === 'search') {
                    params.id = this.currentCampaign;
                    params.products = this.searchProductParam;
                }
            } else {
                this.resetParams();
                params.id = this.currentCampaign;
                params.products = this.searchProductParam;
                params.availableProducts = this.availableProductsParam;
            }
            readCampaign(params)
                .then((res) => {
                    if (typeof res.message === 'undefined') return false;
                    if (res.message.campaign.found === false) {
                        this.sbMsg({
                            type: 'warn',
                            message: this.$t('products.campaign.invalid-campaign') + '...',
                        });
                        this.$router
                            .push({
                                path: '/produse/campanii',
                            })
                            .catch(() => {});
                    }
                    this.campaignDetails = res.message.campaign.data;
                    this.cloneDetails = JSON.parse(JSON.stringify(this.campaignDetails));
                    if (specificSearch !== false) {
                        if (specificSearch === 'available') {
                            if (!reset) {
                                this.availableProducts.data = this.availableProducts.data.concat(
                                    res.message.availableProducts.data
                                );
                                this.availableProducts.moreavailable = res.message.availableProducts.moreavailable;
                            }
                            if (reset) {
                                this.availableProducts = res.message.availableProducts;
                            }
                        } else if (specificSearch === 'search') {
                            if (!reset) {
                                this.searchProduct.data = this.searchProduct.data.concat(res.message.products.data);
                                this.searchProduct.moreavailable = res.message.products.moreavailable;
                            }
                            if (reset) {
                                this.searchProduct = res.message.products;
                            }
                        }
                    } else {
                        this.availableProducts = res.message.availableProducts;
                        this.searchProduct = res.message.products;
                    }

                    for (const e in this.searchProduct.data) {
                        if (this.searchProduct.data[e].stoc == null) this.searchProduct.data[e].stoc = -1;
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'readCampaign',
                            params,
                        },
                    });
                    debugPromise('readCampaign', e);
                });
        },
        resetParams(specificReset) {
            if (specificReset === 'available') {
                this.availableProductsParam = {
                    id: this.currentCampaign,
                    start: 0,
                    limit: 10,
                    search: {
                        byName: '',
                        byManufacturer: '',
                        byCategory: '',
                    },
                };
                return false;
            }

            if (specificReset === 'search') {
                this.searchProductParam = {
                    id: this.currentCampaign,
                    start: 0,
                    limit: 10,
                    search: {
                        byName: '',
                        byManufacturer: '',
                        byCategory: '',
                    },
                };
                return false;
            }

            this.availableProductsParam = {
                id: this.currentCampaign,
                start: 0,
                limit: 10,
                search: {
                    byName: '',
                    byManufacturer: '',
                    byCategory: '',
                },
            };

            this.searchProductParam = {
                id: this.currentCampaign,
                start: 0,
                limit: 10,
                search: {
                    byName: '',
                    byManufacturer: '',
                    byCategory: '',
                },
            };
        },
        validateStartDate(val) {},
        validateEndDate(val) {},
        resetAvailableProducts() {
            this.resetParams('available');
            this.getCampaignDetails('available', true);
        },
        resetSearchProduct() {
            this.resetParams('search');
            this.getCampaignDetails('search', true);
        },
        searchAvailableProduct() {
            if (
                this.availableProductsTerm.length == 0 &&
                this.availableManufacturersTerm.length == 0 &&
                this.availableCategoriesTerm.length == 0
            ) {
                this.resetAvailableProducts();
            } else {
                this.resetParams('available');
                this.availableProductsParam.search.byName = this.availableProductsTerm;
                this.availableProductsParam.search.byManufacturer = this.availableManufacturersTerm;
                this.availableProductsParam.search.byCategory = this.availableCategoriesTerm;
                this.getCampaignDetails('available', true);
            }
        },
        searchProductAction() {
            if (this.searchProductTerm.length == 0) {
                this.resetSearchProduct();
            } else {
                this.resetParams('search');
                this.searchProductParam.search.byName = this.searchProductTerm;
                this.getCampaignDetails('search', true);
            }
        },
        editPrice(index) {
            if (typeof this.searchProduct.data[index] === 'undefined') return false;
            const product = this.searchProduct.data[index];
            const params = {
                idcampanie: this.campaignId,
                id: this.currentCampaign,
                model: product.products_model,
                vendor_ext_id: product.vendor_ext_id || '',
                promo: product.pret_promo,
                stoc: product.stoc,
            };
            saveProductCampaign(params)
                .then((res) => {
                    if (has(res.message, 'status')) {
                        if (parseInt(res.message.status) === 200) {
                            this.sbMsg({
                                type: 'success',
                                message: this.$t('products.campaign.price-changed'),
                            });
                            this.resetParams('search');
                            this.getCampaignDetails('search', true);
                            return false;
                        }
                    }
                    this.sbMsg({
                        type: 'error',
                        message: this.$t('products.campaign.error-price-changed'),
                    });
                    return false;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'saveProductCampaign',
                            params,
                        },
                    });
                    debugPromise('editPrice', e);
                });
        },
        deleteProduct(row) {
            if (isEmpty(row)) return false;
            const params = {
                idcampanie: this.campaignId,
                id: this.currentCampaign,
                model: row.products_model,
                vendor_ext_id: row.vendor_ext_id || '',
            };
            removeProductCampaign(params)
                .then((res) => {
                    if (
                        typeof res.message !== 'undefined' &&
                        typeof res.message.success !== 'undefined' &&
                        res.message.success == 1
                    ) {
                        this.sbMsg({
                            type: 'success',
                            message: this.$t('products.campaign.price-deleted'),
                        });
                        // this.resetParams('search')
                        this.getCampaignDetails('search', true);
                        this.addProductToAvailable(row);
                        return false;
                    } else {
                        this.sbMsg({
                            type: 'warn',
                            message: this.$t('products.campaign.error-price-deleted'),
                        });
                    }
                    return false;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'RemoveProduct',
                            params,
                        },
                    });
                    debugPromise('RemoveProduct', e);
                });
        },
        removeProductFromAvailable(row) {
            for (const i in this.availableProducts.data) {
                if (this.availableProducts.data[i].products_model == row.products_model) {
                    this.availableProducts.data.splice(i, 1);
                    this.availableProductsParam.start--;
                    this.availableProducts.count--;
                    break;
                }
            }
        },
        addProductToAvailable(row) {
            this.availableProducts.data.push(row);
            this.availableProductsParam.start++;
            this.availableProducts.count++;
        },
        async removeAllProducts() {
            const confirm = await this.$confirm(
                'Sigur doriti sa stergeti toate produsele din campanie?',
                'Informatie',
                {
                    confirmButtonText: 'Da',
                    cancelButtonText: 'Nu',
                }
            );

            if (!confirm) {
                return false;
            }

            removeAllProductsFromCampaign({
                idcampanie: this.campaignId,
            })
                .then((res) => {
                    this.sbMsg({
                        type: 'success',
                        message: this.$t('products.campaign.products-removed'),
                    });
                    this.resetParams();
                    this.getCampaignDetails(false);
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'removeAllProductsFromCampaign',
                            params,
                        },
                    });
                    console.log(e);
                });
        },
        addAllUsingFilters() {
            const params = {
                name: this.availableProductsTerm,
                manufacturer: this.availableManufacturersTerm,
                categories: this.availableCategoriesTerm,
                idcampanie: this.campaignId,
                id: this.currentCampaign,
            };

            addAllUsingFilters(params)
                .then((res) => {
                    this.sbMsg({
                        type: 'success',
                        message: this.$t('products.campaign.added-campaign'),
                    });
                    this.resetParams();
                    this.getCampaignDetails(false);
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'addAllUsingFilters',
                            params,
                        },
                    });
                    console.log(e);
                });
        },
        addProduct(row) {
            if (isEmpty(row)) return false;
            const params = {
                idcampanie: this.campaignId,
                id: this.currentCampaign,
                model: row.products_model,
                vendor_ext_id: row.vendor_ext_id,
                stoc: 'undefined' !== typeof row.stoc && row.stoc !== null ? row.stoc : -1,
            };

            addProductCampaign(params)
                .then((res) => {
                    if (has(res.message, 'status')) {
                        if (parseInt(res.message.status) === 200) {
                            this.sbMsg({
                                type: 'success',
                                message: this.$t('products.campaign.added-campaign'),
                            });
                            this.resetParams('search');
                            this.getCampaignDetails('search', true);
                            this.removeProductFromAvailable(row);
                            return false;
                        } else {
                            this.sbMsg({
                                type: 'warn',
                                message: this.$t('products.campaign.error-add-campaign'),
                            });
                            return false;
                        }
                    }
                    this.sbMsg({
                        type: 'warn',
                        message: this.$t('products.campaign.error-add-campaign'),
                    });
                    return false;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'addProductCampaign',
                            params,
                        },
                    });
                    debugPromise('addProduct', e);
                });
        },
        loadMoreAvailable() {
            this.availableProductsParam.start += 10;
            this.getCampaignDetails('available');
        },
        loadMoreSearch() {
            this.searchProductParam.start += 10;
            this.getCampaignDetails('search');
        },
        hasVendorId(product) {
            return product.vendor_ext_id != null && String(product.vendor_ext_id).trim() !== '';
        },
    },
    computed: {
        totalAvailable() {
            return this.availableProducts.count;
        },
        totalSearch() {
            return this.searchProduct.count;
        },
        campaignId() {
            return this.campaignDetails.id_campanie;
        },
        searchProductData() {
            return this.searchProduct != null && Array.isArray(this.searchProduct.data) ? this.searchProduct.data : [];
        },
    },
    created() {
        this.currentCampaign = atob(this.$route.params.id);
        if (this.currentCampaign === '') {
            this.sbMsg({
                type: 'error',
                message: this.$t('products.campaign.invalid-campaign'),
            });
            return false;
        }
        this.resetParams();
        this.getCampaignDetails(false);
    },
};
</script>
<style lang="scss" scoped>
.identification {
    color: #909399;
    font-size: 0.93em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    span {
        strong {
            color: #ff804ae8;
        }
        i {
            vertical-align: middle;
            margin-right: 2px;
            margin-bottom: 3px;
        }

        &:first-child {
            margin-right: 5px;
        }

        &:nth-child(2) {
            margin-left: 5px;
        }
    }
}

.allstoc-set-btn {
    background-color: #f56c6c !important;
    color: #ffffff !important;
    border-radius: 0px 4px 4px 0px !important;
}
</style>
